<template lang="pug">
a(:href='href' :class='{ "navigation-item--active": isActive }' class='navigation-item')
  slot
</template>

<script lang="ts">
import { defineComponent, type PropType, computed } from 'vue'
import { type LocationQueryRaw, type RouteLocationRaw, useLink, useRouter } from 'vue-router'

const NavigationItem = defineComponent({
  props: {
    name: { type: String },
    to: { type: [String, Object] as PropType<RouteLocationRaw> },
    query: { type: [String, Object] as PropType<LocationQueryRaw>, default: () => {} },
    routes: Array as PropType<string[]>,
    exact: Boolean,
  },
  setup(props) {
    const router = useRouter()
    const { href, isActive, isExactActive, route } = useLink({ to: props.to || { name: props.name } })
    return {
      route,
      href,
      isActive: computed(() =>
        props.exact
          ? isExactActive.value || props.routes?.includes(router.currentRoute.value.name as string)
          : isActive.value || router.currentRoute.value.fullPath.startsWith(route.value.path),
      ),
    }
  },
})
export default NavigationItem
</script>
<style lang="stylus">
@import '../styles/variables.styl'
@import '../styles/basic.styl'

.navigation-item
  font-family: $font-lato
  color: var(--grey-400)
  cursor: pointer
  padding: 4px 0px
  background: transparent
  margin: 0
  border: none
  border-bottom: 2px solid transparent
  transition: all 0.2s ease
  font-weight: bold
  letter-spacing: -0.015em
  text-decoration: none
  font-size: 16px
  .navigation--small &
    font-size: 15px
    padding: 2px 0px
  &:focus,
  &:hover
    text-decoration: none
    color: var(--grey-900)
    text-decoration: none
    body.using-mouse &
      outline: 0
  &--active
    color: var(--grey-900)
    border-color: var(--grey-900)
</style>
