<template lang="pug">
div(:class='{ [`navigation--${size}`]: true }' class='navigation')
  slot
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const Navigation = defineComponent({
  props: {
    size: { type: String, default: 'default' },
  },
})
export default Navigation
</script>
<style scoped lang="stylus">
@import '../styles/variables.styl'

.navigation
  display: inline-grid
  grid-gap: 25px
  width: auto
  grid-template-columns: auto
  grid-auto-flow: column
  margin-bottom: 0px
  align-items: center
  white-space: nowrap
</style>
